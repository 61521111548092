// CaifbSection.js
import React from 'react';
import caifb from "./CAI-FB-800.png";

const CaifbSection = () => (
         <div className={'imageContainer'}>
        <img src={caifb} className={"caifb"} alt="File Baby is a proud member of Content Authenticity Initiative"/></div>
);

export default CaifbSection;



