import HeroSection from "./HeroSection";
import React from "react";
import FileBabyLargeLogoSection from "./FileBabyLargeLogoSection";

function HomePage() {
    return (
        <div className="content">
            <h1>Claim Your Files at File Baby</h1>
            <div className={"home-text"}>File Baby is a turnkey software-as-a-service platform that secures digital content authenticity and provenance, empowers you to be more creative, and helps to protect your work from AI consumption.</div>
            <FileBabyLargeLogoSection />


            <HeroSection />
        </div>
    );
}

export default HomePage;
