// HeroSection.js
import React from 'react';
import SubscribeBtnSection from "./SubscribeBtnSection";


const NotFoundPage = () => (
    <div className={"content"}>
        <h1>Page Not Found</h1>
        <p className={"normal-text"}>Proceed to File Baby's <a href={"/"}>Home Page</a></p>
       <SubscribeBtnSection />
    </div>
);


export default NotFoundPage;