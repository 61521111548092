// HeroSection.js
import React from 'react';
import SubscribeBtnSection from "./SubscribeBtnSection";

const SubscriptionsPage = () => (
    <div className="content">
        <h1>Subscriptions</h1>
        <p>At File Baby, we have one simple subscription plan: $99 a month for unlimited claims, unlimited storage, unlimited shares, unlimited use of File Baby's built-in AI Image Generator and expert assistant; and all the latest, exciting File Baby features. By purchasing a one-year subscription at $999, you can save $189 a year over our monthly rate.</p>
        <p>If you would like to become a File Baby ambassador, you may be eligible for substantial price reductions and special offers. </p>
        <p>Because we strive to make File Baby's protection available to everyone, File Baby has special subscription rates for students and teachers, seniors, freelance artists, musicians and writers, and for members of certain groups; if you are wondering if we have a discount for you, please ask. </p><p>Please call us at 479-709-2111 or email us at info@file.baby if this is of interest to you.

        </p>
        <SubscribeBtnSection />
    </div>
);


export default SubscriptionsPage;