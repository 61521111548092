// SubscribeBtnSection.js
import React from 'react';

const SubscribeBtnSection = () => (
    <section>
        <div className=""><button tabIndex="0" className="subscribe-btn" onClick={() => window.location.href = 'https://subscribe.file.baby'}>
            Subscribe
        </button></div>
    </section>
);

export default SubscribeBtnSection;
