// HeroSection.js
import React from 'react';
import SubscribeBtnSection from "./SubscribeBtnSection";


const AboutPage = () => (
    <div className={"content"}>
        <h1>About File Baby</h1>
        <p className={"normal-text"}>At File Baby, we are passionate about ensuring the authenticity and provenance of digital content. Our mission is to provide individuals and businesses with the tools and knowledge they need to verify the origin and integrity of digital files. Our team of experts is dedicated to staying at the forefront of technology and innovation in the field of content authenticity. We understand the importance of trustworthy and authentic content in today's world, where misinformation and falsified information are prevalent. </p><p>With our user-friendly, one-of-a-kind platform, robust authentication processes which embed  unique content credentials, we empower our users to confidently verify the authenticity and provenance of their digital assets.  Whether it's validating the source of a document, image, or video, our goal is to provide a reliable and secure solution. We believe that transparency and trust are essential in the digital age, and we are committed to helping our users protect the integrity of their content.</p>
            <p>
                File Baby also offers protection on a wide range of content beyond just digital files. This can include physical documents, compositions, music, MP3s, artwork, photographs, films/videos, and other types of creative work.  Essentially, anything that holds value and can be at risk of unauthorized use or replication can be protected using our platform.  Join us in our mission to uphold the standards of authenticity and provenance in the digital realm.</p>

            <p>If you have any specific content in mind that needs protection from AI misuse, augmentation and manipulation, contact us for more details!  info@file.baby</p>
       <SubscribeBtnSection />
    </div>
);


export default AboutPage;