// ServicesSection.js
import React from 'react';
import inspect from './inspect.gif';
const ReadyToInspectSection = () => (
    <section className="home-text">
        <h2>Ready to Inspect</h2>
            <div className={"services"}>
       <img className="moonrise" src={inspect} alt="moonrise sunset by Karen Kilroy" />
        </div>
    </section>
);

export default ReadyToInspectSection;
