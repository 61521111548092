// HeroSection.js
import React from 'react';
import SubscribeBtnSection from "./SubscribeBtnSection";
import PressIcon from "./press_icon-60.png"


const PressPage = () => (
    <div className="content">
        <h1>Press</h1>
        <div><a href={"https://press.file.baby"}><img src={PressIcon} alt={"Press Pass Icon"}/></a></div>
        <p>Electronic Press Kits, Press Releases, Staff Bios, Photos, and more are available at <a href={"https://press.file.baby"}>press.file.baby.</a> </p>
        <p>To arrange an interview, please contact File Baby President Orson Weems at 479-709-2111 or by email at orson@knowbots.org.</p>
        <SubscribeBtnSection />
    </div>
);


export default PressPage;