// TermsOfUse.js
import React from 'react';
import SubscribeBtnSection from "./SubscribeBtnSection";

const TermsOfUsePage = () => (
<section className="content">
    <h1 id="terms-of-use">Terms of Use</h1>
            <p>Welcome to File Baby. By accessing and using our website (the "Service"), you agree to comply with the following terms of use.</p>

    <p>Use of Service: File Baby provides a digital file management service. Users are responsible for the content they upload and share. Illegal use of the Service is strictly prohibited. Intellectual Property: Content uploaded to File Baby remains the intellectual property of the original owner. Users must not infringe upon the rights of others.</p>
    <p>Disclaimers: File Baby is provided on an 'as is' basis. We disclaim all warranties and guarantees related to the Service.</p>
    <p> Limitation of Liability: File Baby is not liable for any direct, indirect, incidental, or consequential damages resulting from the use of the Service.</p>

            <p>Amendments: We reserve the right to update these Terms of Use at any time. Continued use of the Service after such changes constitutes acceptance of the new terms.</p>

        <p>Contact Us: If you have any questions or concerns about these Terms, please contact us at filebaby@knowbots.org</p>
    <SubscribeBtnSection />
    </section>
);

export default TermsOfUsePage;
