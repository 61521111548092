// HeroSection.js
import React from 'react';
import SubscribeBtnSection from "./SubscribeBtnSection";
import CaifbSection from "./CaifbSection";
import SignInBtnSection from "./SignInBtnSection";

const HeroSection = () => (
    <div>
      <SubscribeBtnSection />
        <div></div>

            <div className={"homepage-text odd"}>
                File Baby is a digital assistant designed to be an expert in content authenticity and provenance, and combines artificial intelligence and advanced data analysis to understand digital content nuances.
            </div>
            <div className ={"homepage-text even"}>
                Fighting against misinformation, forgery, and tampering in the digital realm is something File Baby knows well.
            </div>
        <div className={"homepage-text odd"}>

                File Baby examines digital content with a keen eye for detail, analyzing and verifying the origins and history of files and other various content.
            </div>
        <div className={"homepage-text even"}>

                Tracing the lineage of media, documents, and other digital assets with lightning speed and precision is easy with File Baby.
            </div>
        <div className={"homepage-text odd"}>

        File Baby helps you to protect your creative integrity, name, image, likeness, and voice.
            </div>
        <div className={"homepage-text even"}>

        File Baby's mission is to uphold the integrity of information, protect against deception, and empower users with genuine and trustworthy content.
            </div>
        <div className={"homepage-text odd"}>

        A beacon of trustworthiness in a world where digital content proliferates at an unheard of pace, File Baby is always ready to help you.
        </div>
        <div className={"even"}>File Baby is Your Content Authenticity and Provenance Platform, powered by technology from
            the <a href={"https://contentauthenticity.org"}>Content Authenticity Initiative (CAI)</a> and the <a href={"https://c2pa.org"}>Coalition for Provenance and Authenticity (C2PA)</a> via C2PA Contributing Member AI research lab  <a href={"https://friendsofjustin.knowbots.org"}>Friends of Justin</a>.
            <CaifbSection />
        </div>
        <div className={"odd"}>
            <h2>Already Have File Baby?</h2>
            <SignInBtnSection />
        </div>

    </div>
);


export default HeroSection;



