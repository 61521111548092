import React from "react";

const VideoSection = () => (
    <div className="video">

        <iframe src="https://www.youtube.com/embed/videoseries?si=BXJO_6Ev21x99CfL&amp;list=PL2EG1id9M5HP49i_pngfHZxX4KiY2E5NE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </div>
);

export default VideoSection;
