// SubscribeBtnSection.js
import React from 'react';

const SubscribeBtnSection = () => (
    <section className="subscribe">
        <button tabIndex={"0"} className="signin" onClick={() => window.location.href = 'https://my.file.baby'}>
            SIGN IN
        </button>
    </section>
);

export default SubscribeBtnSection;
