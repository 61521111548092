// HeroSection.js
import React from 'react';
import SubscribeBtnSection from "./SubscribeBtnSection";

const FAQPage = () => (
    <div className="content">
        <h1>Frequently Asked Questions</h1>
        <p>Q: What is File Baby?</p>
        <p>A: File Baby is....</p>
    <SubscribeBtnSection />
    </div>
);


export default FAQPage;