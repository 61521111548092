// ContactUsPage.js
import React from 'react';
import SubscribeBtnSection from "./SubscribeBtnSection";


const ContactUsPage = () => (
    <div className={"content"}>
        <h1>Contact Us</h1>
        <p className={"normal-text"}>We would love to hear from you! Please contact us one of the following ways:</p>
                <p><ul>
                <li>Email: info@file.baby</li>
                    <li>Phone: 479-709-2111</li>
                    <li><a href={"https://www.youtube.com/@FileBaby"}>@File Baby on YouTube</a></li>
                    <li><a href={"https://www.linkedin.com/company/filebaby/?viewAsMember=true"}>File Baby on LinkedIn</a></li>
                </ul>
                </p>
            <p>If you have any specific content in mind that needs protection from AI misuse, augmentation and manipulation, contact us for more details.</p>
       <SubscribeBtnSection />
    </div>
);


export default ContactUsPage;